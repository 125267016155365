const chessModal = {
  init: () => {
    document.querySelectorAll(".chess-row .img-container img").forEach((i) => {
      i.addEventListener("click", chessModal.openWithoutAnimation);
    });
  },
  openWithAnimation: (e) => {
    document.body.classList.add("modal-open");
    const el = e.target.classList.contains("img-container")
      ? e.target
      : e.target.closest(".img-container");
    const imgEl = el.querySelector("img");

    const boundingRect = el.getBoundingClientRect();

    const modalContainer = document.createElement("div");
    modalContainer.classList.add("modal-container");
    modalContainer.style.top = `${boundingRect.top}px`;
    modalContainer.style.left = `${boundingRect.left}px`;
    modalContainer.style.width = `${el.offsetWidth}px`;
    modalContainer.style.height = `${el.offsetHeight}px`;
    modalContainer.addEventListener("click", () => {
      modalContainer.parentElement.removeChild(modalContainer);
      document.body.classList.remove("modal-open");
    });
    const modalImage = document.createElement("div");
    modalImage.classList.add("img-container");
    modalImage.style.backgroundImage = `url('${imgEl.dataset.modal}')`;

    modalContainer.appendChild(modalImage);
    const modalCloseBtn = document.createElement("div");
    modalCloseBtn.classList.add("close-btn");
    modalContainer.appendChild(modalCloseBtn);
    document.body.appendChild(modalContainer);
    setTimeout(() => {
      modalContainer.classList.add("opened");
    }, 500);
  },
  openWithoutAnimation: (e) => {
    document.body.classList.add("modal-open");
    //const el = e.target.classList.contains('img-container') ? e.target : e.target.closest('.img-container');
    const imgEl = e.target;

    const modalContainer = document.createElement("div");
    modalContainer.classList.add("modal-container");
    modalContainer.addEventListener("click", () => {
      modalContainer.parentElement.removeChild(modalContainer);
      document.body.classList.remove("modal-open");
    });
    const modalImage = document.createElement("div");
    modalImage.classList.add("img-container");
    modalImage.innerHTML = `<div class="loader"><div class="loader-wheel"></div></div>`;

    const bgImage = document.createElement("img");
    bgImage.onload = () => {
      modalImage.innerHTML = "";
      modalImage.style.backgroundImage = `url('${imgEl.dataset.modal}')`;
    };
    bgImage.src = imgEl.dataset.modal;
    //modalImage.innerHTML = '';
    //modalImage.style.backgroundImage = `url('${imgEl.dataset.modal}')`;

    modalContainer.appendChild(modalImage);
    const modalCloseBtn = document.createElement("div");
    modalCloseBtn.classList.add("close-btn");
    modalContainer.appendChild(modalCloseBtn);
    document.body.appendChild(modalContainer);
    modalContainer.classList.add("opened");
  },
};
